import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import { setMenuColor } from '../state/menuColor';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import getHtmlFromRedactorField from '../utils/getHtmlFromRedactorField';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';

const StyledWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	width: 100%;
`;

const StyledContent = styled.div`
	padding: 124px 24px 0;
	@media (min-width: 768px) and (max-width: 1365px) {
		padding: 124px 64px 0;
	}
	@media (min-width: 1366px) {
		padding: 243px 0 64px;
		max-width: 1310px;
	}
`;

const StyledHeader = styled.h1`
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 36px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 45px;
	@media (min-width: 1366px) {
		line-height: 72px;
		font-size: 55px;
	}
`;
const StyledDescription = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 24px;
	margin: 48px 0;
	p {
		min-height: 18px;
	}
`;

const policyPrivacyPage = ({ data: { privacyPolicyData }, pageContext }) => {
	const pageData = privacyPolicyData?.nodes?.[0] || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}
	}, []);

	const blocks = privacyPolicyData?.nodes?.[0]?.infoBlock || false;
	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<StyledWrapper className="last">
				<StyledContent>
					{blocks &&
						blocks.map((block) => {
							const header = block.header || '';
							const description = block.description || '';
							return (
								<>
									<StyledHeader>{header}</StyledHeader>
									<StyledDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(description)} />
								</>
							);
						})}
				</StyledContent>
			</StyledWrapper>
		</>
	);
};
export default policyPrivacyPage;

export const query = graphql`
	query ($locale: String) {
		privacyPolicyData: allDatoCmsPolicyPrivacyPage(filter: { locale: { eq: $locale } }) {
			nodes {
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				infoBlock {
					description
					header
				}
			}
		}
	}
`;
